.app {
  background-color: #282c34;
  font-family: Montserrat;

  &-header {
    align-items: center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-wrapper {
      text-align: center;

      &-poster {
        padding-top: 16px;
        width: 80%;
      }
    }
  }

  &-content {
    min-height: 100vh;
    background-size: cover;

    &-title {
      font-size: 80px;
      font-weight: bold;
      margin-top: 100px;
    }
    &-subtitle {
      color: gray;
      font-size: 30px;
    }

    &-carbon {
      color: gray;
      margin-top: 100px;

      &-value {
        font-size: 80px;
      }

      .ant-card-head {
        background: var(--ui-highlight-green, #05B012) !important;
        color: white;
      }
    }

    &-footnote {
      margin-top: 20px;
    }
  } 

  &-footer {
    color: white;
    margin-top: 20px;
    padding: 16px 0;
    text-align: center;

    a {
      color: inherit;
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
